import ErrorFallback from "conponents/core/ErrorFallBack";
import Loading from "conponents/core/Loading";
import HeadWrap from "conponents/header";
import Generator from "pages/generator";
import Home from "pages/home";
import { memo, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import MintContextWrap from "./contexts/MintContext";
import PinataContextWrap from "./contexts/PinataContext";
import TronContextWrap from "./contexts/TronContext";
import UploadPage from "./pages/uploadCloud/UploadPage";
import { useWidthScreen } from "./helpers/hooks/useWidthScreen";

const App = () => {
  const { width } = useWidthScreen();
  if (width < 1024) {
    return (
      <Wrap>
        <p>Only support desktop </p>
      </Wrap>
    );
  }
  return (
    <AppStyle>
      {/* <HeliaProvider> */}
      <TronContextWrap>
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<Outlet />}>
                  <Route
                    index
                    element={
                      <HeadWrap>
                        <Home />
                      </HeadWrap>
                    }
                  />
                  <Route
                    path="/generate"
                    element={
                      <HeadWrap>
                        <Generator />
                      </HeadWrap>
                    }
                  />
                  <Route
                    path="/upload"
                    element={
                      <MintContextWrap>
                        <PinataContextWrap>
                          <UploadPage />
                        </PinataContextWrap>
                      </MintContextWrap>
                    }
                  />
                </Route>
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </TronContextWrap>
      {/* </HeliaProvider> */}
      <ReactTooltip />
    </AppStyle>
  );
};
export default App;
const AppStyle = memo(styled.div`
  height: 100dvh;
  overflow: auto;
  /* overflow: hidden; */
`);
const Wrap = styled.div`
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111;
  p {
    color: #fff;
  }
`;

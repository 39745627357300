import { WalletActionButton } from "@tronweb3/tronwallet-adapter-react-ui";
import React from "react";
import styled from "styled-components";
import logo from "assets/images/logo.png";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <WrapContain>
      <Wrap>
        <Link to={"/"}>
          <img src={logo} alt="logo" style={{ width: "100px" }} />
        </Link>
        <div>
          <WalletActionButton className="adapter-bt"></WalletActionButton>
        </div>
      </Wrap>
    </WrapContain>
  );
};

export default Header;
const WrapContain = styled.div`
  background-color: #000000;
`;
const Wrap = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  .adapter-bt {
    padding: 10px 20px;
    background: #2f9c95;
    border-radius: 5px;
  }
`;

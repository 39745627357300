import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import abi from "constants/abiMintFactory.json";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Loading from "../../../conponents/core/Loading";
import { useMintContext } from "../../../contexts/MintContext";
import { useTronContext } from "../../../contexts/TronContext";
import NftItem from "./NftItem";
import Pagination from "../../../conponents/core/Pagination";

const MarketApe = () => {
  const [loading, setLoading] = useState(true);
  const { contract, symbol } = useMintContext();
  const { tronWeb } = useTronContext();
  const { address } = useWallet();
  const [nfts, setNfts] = useState<any[]>([]);
  const getAllNft = async () => {
    try {
      const instanceContract = await tronWeb.contract(abi, contract);
      const result = await instanceContract.balanceOf(address).call();
      const data = await tronWeb.trx.listTokens(10, 0);
      console.log({ data });
      //   console.log({ result });
    } catch (error) {
      console.log("error get nfts", error);
    }
  };
  const getAllNftFromApi = async () => {
    try {
      const url = " https://api-gateway-staging.apenft.io/graphql";
      const query = `fragment paginationFields on PagingInfo {
  cursor
  has_next
  __typename
}

fragment userFields on User {
  profile {
    account_address
    user_name
    profile_image_url
    __typename
  }
  __typename
}

fragment currencyFields on Currency {
  address
  decimal
  icon
  symbol
  __typename
}

fragment asksFields on NFTBestAsk {
  bestAskCreatedDate
  bestAskExpirationDate
  bestAskListingDate
  bestAskOrderQuantity
  bestAskOrderString
  bestAskOrderType
  bestAskPrice
  bestAskPriceBase
  bestAskPriceCNY
  bestAskPriceUSD
  bestAskSaleKind
  bestAskToken
  bestAskTokenId
  chain
  chainId
  contractAddress
  currency {
    ...currencyFields
    __typename
  }
  __typename
}

fragment bidsFields on NFTBestBid {
  bestBidCreatedDate
  bestBidListingDate
  bestBidOrderQuantity
  bestBidOrderString
  bestBidPrice
  bestBidPriceBase
  bestBidPriceCNY
  bestBidPriceUSD
  bestBidToken
  bestBidTokenId
  chain
  chainId
  contractAddress
  currency {
    ...currencyFields
    __typename
  }
  __typename
}

fragment salesFields on NFTLastSale {
  currency {
    ...currencyFields
    __typename
  }
  lastSalePrice
  lastSaleQuantity
  __typename
}

fragment listOrderFieldsV3 on OrderItemV3 {
  pay_price
  pay_price_in_usd
  end_time
  close_time
  order_kind
  exchange_contract
  pay_currency {
    ...currencyFields
    __typename
  }
  maker
  taker
  taker_user {
    ...userFields
    __typename
  }
  order_hash
  maker_user {
    ...userFields
    __typename
  }
  __typename
}

fragment orderItemFieldsV3 on OrderItemV3 {
  ...listOrderFieldsV3
  start_time
  asset_type
  asset_contract
  asset_quantity
  asset_token_id
  pay_token
  is_private
  english_auction_reserve_price
  __typename
}

fragment assetsFields on Token {
  token_id
  contract_address
  image_url
  animation_url
  name
  token_type
  token_uri
  trade_version
  collection {
    royalty
    royalty_address
    name
    is_verified
    contract_address
    image_url
    banner_image_url
    description
    stat {
      assets_total
      owners_total
      floor_price
      volume_total
      __typename
    }
    payment_tokens {
      ...currencyFields
      __typename
    }
    __typename
  }
  asks_v2 {
    ...asksFields
    __typename
  }
  bids_v2 {
    ...bidsFields
    __typename
  }
  sales_v2 {
    ...salesFields
    __typename
  }
  asks_v3 {
    ...orderItemFieldsV3
    __typename
  }
  bids_v3 {
    pay_price
    pay_currency {
      ...currencyFields
      __typename
    }
    __typename
  }
  sales_v3 {
    last_sale_price
    currency {
      ...currencyFields
      __typename
    }
    __typename
  }
  owners {
    owner {
      ...userFields
      __typename
    }
    __typename
  }
  __typename
}

query getAssets($pagination: Pagination, $sort: AssetSortBy, $price: PriceRangeFilter, $statuses: [AssetStatus], $collections: [String], $user_account: String, $owners: [String], $creators: [String], $attributes: [AttributesFilter], $search: String, $categories: [String], $currencies: [String], $is_recommend: Boolean, $collection_types: [assetListCollectionTypes]) {
  assets(
    sort: $sort
    pagination: $pagination
    price: $price
    statuses: $statuses
    collections: $collections
    user_account: $user_account
    owners: $owners
    creators: $creators
    attributes: $attributes
    search: $search
    categories: $categories
    currencies: $currencies
    is_recommend: $is_recommend
    collection_types: $collection_types
  ) {
    items {
      ...assetsFields
      __typename
    }
    pagination {
      ...paginationFields
      total
      __typename
    }
    __typename
  }
}`;
      const options = {
        method: "GET",
        body: JSON.stringify({
          pagination: {
            first: 60,
          },
          sort: "RecentlyListed",
          owners: [address],
        }),
      };
      const request = new Request(url, options);
      fetch(request)
        .then((response) => {
          console.log({ response });
          // handle the response from the server
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.log("error get ape nft", error);
    }
  };
  // useEffect(() => {
  //   if (address) {
  //     getAllNftFromApi();
  //   }
  // }, [address]);
  const pageSize = 12;
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  async function getAllNFTOwners(page) {
    setLoading(true);
    try {
      const instanceContract = await tronWeb.contract(abi, contract);
      const totalSupply = await instanceContract.totalSupply().call(); // Get total supply of NFTs
      setTotalItem(totalSupply);
      // const totalSupply = metadatas.length;
      const offset = (page - 1) * pageSize;
      const limit = offset + pageSize;
      const owners = [] as any;
      const limitId = limit - totalSupply > 0 ? totalSupply : limit;
      for (let tokenId = offset; tokenId < limitId; tokenId++) {
        try {
          const [owner, tokenURI] = await Promise.all([
            instanceContract.ownerOf(tokenId).call(),
            instanceContract.tokenURI(tokenId).call(),
          ]);
          // console.log({ tokenId, owner, tokenURI });
          if (address === tronWeb.address.fromHex(owner)) {
            owners.push({ tokenId, owner, tokenURI });
          }
        } catch (err) {
          console.error(`Error getting owner for token ID ${tokenId}:`, err);
        }
      }

      setNfts(owners);
    } catch (error) {
      console.log("error get nfts", error);
    } finally {
      setLoading(false);
    }
  }
  async function getAllTokenIdsFromEvents() {
    try {
      const instanceContract = await tronWeb.contract(abi, contract);

      let result = await instanceContract.balanceOf(address).call();
      console.log("result: ", tronWeb.toDecimal(result));
    } catch (error) {
      console.log("error get nfts", error);
    }
  }
  // Example usage
  useEffect(() => {
    if (page > 1) {
      getAllNFTOwners(page);
      return;
    }
    if (address && contract) {
      // console.log("address: ", address, contract, page);
      const timerWaitTransaction = setTimeout(() => {
        getAllNFTOwners(page);
      }, 6000);
      return () => {
        clearTimeout(timerWaitTransaction);
      };
    }
  }, [address, contract, page]);
  // console.log({ nfts });
  if (loading) return <Loading size="large" />;
  return (
    <Wrap>
      <div className="nft-total">
        <p>Total Nfts: {`${totalItem || 0}`}</p>
      </div>
      <NftList>
        {nfts.length > 0 &&
          nfts.map((item, index) => {
            return (
              <div key={index} className="nft-item">
                <NftItem
                  tokenUri={item.tokenURI}
                  tokenId={item.tokenId}
                  contract={contract ?? ""}
                  symbol={symbol}
                />
              </div>
            );
          })}
      </NftList>
      <Pagination
        totalPage={Math.ceil(totalItem / pageSize)}
        setPage={setPage}
        currentPage={page}
      />
    </Wrap>
  );
};

export default MarketApe;
const Wrap = styled.div`
  padding: 30px;
  margin-bottom: 100px;
  .nft-total {
    margin-bottom: 20px;
  }
`;
const NftList = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
  /* height: min(100vh, 500px); */
  overflow: auto;
  margin-bottom: 30px;
`;

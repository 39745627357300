import { NFTGeneratorContext, TDimen } from "contexts/NFTGeneratorContext"
import { useDebounce } from "helpers/hooks/useDebounce"
import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"


const ExternalInput = () => {
    const { state: { collection: { extenalLink } }, onUpdateCollection } = useContext(NFTGeneratorContext)
    const [url, setUrl] = useState(extenalLink)
    const externalDebounce = useDebounce(url, 300)
    useEffect(() => {
        if (externalDebounce) {
            onUpdateCollection({ extenalLink: externalDebounce })
        }
    }, [externalDebounce]);
    return (
        <ExternalInputStyle>
            <label htmlFor="collection_external_url">Extenal URL</label>
            <input id="collection_external_url"
                value={url}
                onChange={(e: any) => { setUrl(e.target.value) }} />
        </ExternalInputStyle>
    )
}
export default ExternalInput
const ExternalInputStyle = memo(styled.div`

`)
import {
  IPrototypeItem,
  ITraits,
  NFTGeneratorContext,
} from "contexts/NFTGeneratorContext";
import { memo, useContext } from "react";
import styled from "styled-components";
import PropertiesItem from "./propertiesItem";
import useUploadIpfs from "../../../helpers/hooks/useUploadIpfs";
import { renderCanvasImageWithBlob } from "../../../helpers/renderCanvasImage";

const SavedPrototype = () => {
  const { onUploadFileSingle } = useUploadIpfs();
  const {
    state: { prototypes, layer, generated },
    onUpdateActivePrototype,
  } = useContext(NFTGeneratorContext);
  // console.log("prototypes", prototypes, generated);
  const urltoFile = (img: string) => {
    const data = img.replace(/^data:image\/\w+;base64,/, "");
    const buf = Buffer.from(data, "base64");
    return buf;
  };
  const onMintFile = async () => {
    const infoImage = prototypes?.active?.imgUri;
    console.log({ infoImage });
    // const temp = infoImage;
    // let layerList = [...Object.values(temp ?? "")] as string[];
    // const generatedImg = renderCanvasImageWithBlob({
    //   nftImages: layerList,
    //   id: "render_export",
    //   size: [200, 200],
    //   imageType: "image/png",
    // });
    // console.log({ generatedImg });
    // if (!infoImage) return;
    // const fileUrl = urltoFile(generatedImg);
    // console.log({ fileUrl });
    onUploadFileSingle(infoImage);
  };
  return (
    <SavedPrototypeStyle>
      <div className="sps_list">
        <div className="spsl_head">
          <p>Saved prototypes</p>
        </div>
        <div className="spsl_body">
          {prototypes?.saved &&
            prototypes?.saved.map((item: IPrototypeItem) => {
              return (
                <div
                  className="spsl"
                  key={item.id}
                  onClick={() => {
                    onUpdateActivePrototype(item);
                  }}
                >
                  <div className="spsl_img">
                    <img src={item.imgUri} alt="" />
                  </div>
                  <p>{item.title}</p>
                </div>
              );
            })}
        </div>
        <div>
          <button onClick={onMintFile}>Mint</button>
        </div>
      </div>
      <div className="sps_properties">
        <div className="spsp_head">
          <p>Traits</p>
        </div>
        <div className="spsp_body">
          {prototypes?.active &&
            layer.length > 0 &&
            prototypes?.active.traits.map((item: ITraits) => {
              return (
                <PropertiesItem
                  key={item.id}
                  id={item.id}
                  layerId={item.layer}
                />
              );
            })}
        </div>
      </div>
    </SavedPrototypeStyle>
  );
};
export default SavedPrototype;
const SavedPrototypeStyle = memo(styled.div`
  height: 100%;
  .sps_list {
    height: 40%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .spsl_head {
      padding: 10px;
      background-color: #e5f9e0;
    }
    .spsl_body {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      padding: 10px;
      overflow-y: scroll;
      .spsl {
        width: calc((100% - (10px * 3)) / 4);
        text-align: center;
        font-size: 12px;
        .spsl_img {
          background: #f3f4f6;
          border-radius: 5px;
        }
        p {
          margin-top: 5px;
        }
      }
    }
  }
  .sps_properties {
    height: 60%;
    overflow: hidden;
    .spsp_head {
      padding: 10px;
      background: #e5f9e0;
    }
    .spsp_body {
      padding: 10px;
    }
  }
`);

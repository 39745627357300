import styled from "styled-components";
import { useMintContext } from "../../../../contexts/MintContext";
const titleStep = {
  1: "Create Contract",
  2: "Upload Cloud",
  3: "Mint NFT",
  4: "Market Ape",
};
const StepContainer = () => {
  const { step, onChangeStep } = useMintContext();
  return (
    <Wrap className="container">
      <PaginationStep className="pagination">
        {[...Array(4)].map((item, index) => (
          <StepItem
            key={index}
            className={step >= index + 1 ? "active" : ""}
            onClick={() => onChangeStep(index + 1)}
          >
            <p className="" style={{}}>
              {titleStep[index + 1]}
              {step > index + 1 ? " ✓" : ""}
              {step === index + 1 ? " (current)" : ""}
            </p>
          </StepItem>
        ))}
      </PaginationStep>
    </Wrap>
  );
};

export default StepContainer;
const Wrap = styled.div`
  background: #111;
  border-radius: 999px;
  margin-bottom: 20px;
`;
const PaginationStep = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 0;
`;
const StepItem = styled.div`
  cursor: pointer;
  width: 100%;
  height: 40px;
  /* width: 20px;
  height: 20px; */
  /* border-radius: 999px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccc;
  position: relative;

  :before {
    content: "";
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 20px 0 20px 30px;
    border-color: transparent transparent transparent #111;
    transform: rotate(0deg);
    position: absolute;
    left: 100%;
    z-index: 2;
    display: none;
  }
  :after {
    content: "";
    width: 1px;
    height: 100%;
    background: #2f9c95;
    position: absolute;
    left: 100%;
    z-index: 1;
  }
  :last-child {
    :after {
      display: none;
    }
    :before {
      display: none;
    }
  }
  &.active {
    background: #2f9c95;
    color: white;

    :before {
      border-color: transparent transparent transparent #2f9c95;
      display: block;
    }
  }
`;

import { useState } from "react";
import { useHelia } from "../../provider/HeliaProvider";
type IData = {
  path: string;
  content: any;
};
const useUploadIpfs = () => {
  const { helia, error, fs, starting, jsonHelia } = useHelia();
  const [ipfsHashs, setIpfsHashs] = useState<any>("");
  const onUploadFile = async (data = [] as IData[], metadata = [] as any[]) => {
    console.log({ data, metadata });
    if (fs) {
      try {
        console.log("here");
        let arrEntry: string[] = [];
        let arrMetaData: string[] = [];
        for await (const entry of fs.addAll(data)) {
          console.info("entry", entry, entry.cid.toString());
          arrEntry.push(entry.cid.toString());
        }
        setIpfsHashs(arrEntry);
        // const cidString = cid.toString();
        // setIpfsHash(cidString);
        // console.log("File uploaded to IPFS with hash:", cidString);
        return;
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };
  const onUploadFileSingle = async (data: any) => {
    console.log("image upload file single", data);
    if (fs) {
      try {
        console.log("here");
        let arrEntry: string[] = [];
        let arrMetaData: string[] = [];
        const base64ToUint8Array = (base64: string): Uint8Array => {
          const binaryString = atob(base64.split(",")[1]);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          return bytes;
        };
        const bytes = base64ToUint8Array(data);
        console.log("bytes", JSON.stringify(bytes));
        const cid = await fs.addBytes(bytes);
        const cidString = cid.toString();
        setIpfsHashs(cidString);
        console.log("File uploaded to IPFS with hash:", cidString);
        return;
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };
  const onUploadMetaData = async (data = [] as IData[]) => {};
  return { onUploadFile, ipfsHashs, onUploadFileSingle };
};

export default useUploadIpfs;

import { NFTGeneratorContext } from 'contexts/NFTGeneratorContext'
import React, { ChangeEvent, useContext, useDeferredValue, useEffect, useState } from 'react'
import styled from 'styled-components'

const StartIndex = () => {
    const { onUpdateCollection, state: { collection } } = useContext(NFTGeneratorContext)
    const [startIndex, setStartIndex] = useState(collection.startIndex)
    const defferedStartIndex = useDeferredValue(startIndex)
    useEffect(() => {
        if (!isNaN(defferedStartIndex)) {
            onUpdateCollection({ startIndex: defferedStartIndex })
        }
    }, [defferedStartIndex])
    return (
        <Wrap>
            <label htmlFor="sic-start-index">Start index</label>
            <input id="collection-start-index"
                value={startIndex}
                pattern="[0-9]*"
                onChange={(e: any) => {
                    if ((e.target.value.match(/^[0-9]*$/) !== null)) {
                        setStartIndex(+e.target.value)
                    }
                }} />
        </Wrap>
    )
}

export default StartIndex
const Wrap = styled.div`
    
`
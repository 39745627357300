import { breakpointsMedias } from "configs/breakpoints";
import { memo } from "react";
import styled, { css } from "styled-components";

interface LoadingProps {
  relative?: boolean;
  size?: "normal" | "small" | "tiny" | "large";
  color?: "primary" | "white";
  centering?: boolean;
}
const Loading = ({
  relative,
  size = "normal",
  color = "primary",
  centering,
}: LoadingProps) => {
  if (relative) {
    return (
      <LoadingElement centering className={`relative ${size}`} role={color} />
    );
  }
  return <LoadingElement className={size} role={color} />;
};

export default memo(Loading);
interface LoadingElementProps {
  centering: boolean;
}
const LoadingElement = memo(styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 4px solid transparent;
  border-left-color: rgb(47, 156, 149);
  border-right-color: rgb(47, 156, 149);
  border-top-color: rgb(47, 156, 149);
  border-radius: 999px;
  &.relative {
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }
  &.small {
    width: 30px;
    height: 30px;
    border-width: 2px;
  }
  &.tiny {
    width: 20px;
    height: 20px;
    border-width: 3px;
  }
  &.large {
    width: 60px;
    height: 60px;
    border-width: 3px;
  }
  &[role="white"] {
    border-left-color: #fff;
    border-right-color: #fff;
    border-top-color: #fff;
  }
  ${(props: LoadingElementProps) =>
    props.centering
      ? css`
          animation: rotate-center 0.7s ease-in-out infinite both;
        `
      : css`
          animation: rotate-center-1 0.7s ease-in-out infinite both;
        `}
  @keyframes rotate-center {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotate-center-1 {
    0% {
      transform: translate(-50%, -50%) rotate(0);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`);

import React, { useCallback, useEffect, useState } from "react";
import { createHelia, HeliaLibp2p, DefaultLibp2pServices } from "helia";
import { unixfs, UnixFS } from "@helia/unixfs";
import { json, JSON } from "@helia/json";
import type { Libp2p } from "@libp2p/interface";

export const HeliaContext = React.createContext({
  helia: undefined as HeliaLibp2p<Libp2p<DefaultLibp2pServices>> | undefined,
  fs: undefined as UnixFS | undefined,
  starting: true,
  error: null as boolean | null,
  jsonHelia: undefined as JSON | undefined,
});
const HeliaProvider = ({ children }: { children: React.ReactNode }) => {
  const [helia, setHelia] =
    useState<HeliaLibp2p<Libp2p<DefaultLibp2pServices>>>();
  const [fs, setFs] = useState<UnixFS>();
  const [jsonHelia, setJsonHelia] = useState<JSON>();
  const [starting, setStarting] = useState(true);
  const [error, setError] = useState<boolean | null>(null);

  const startHelia = useCallback(async () => {
    if (helia) {
      console.info("helia already started");
    } else if ((window as any).helia) {
      console.info("found a windowed instance of helia, populating ...");
      setHelia((window as any).helia);
      setFs(unixfs(helia as any));
      setJsonHelia(json(helia as any));
      setStarting(false);
    } else {
      try {
        console.info("Starting Helia");
        const helia = await createHelia();
        console.log("helia", helia);
        setHelia(helia);
        setFs(unixfs(helia));
        setJsonHelia(json(helia));
        setStarting(false);
      } catch (e) {
        console.error(e);
        setError(true);
      }
    }
  }, []);

  useEffect(() => {
    startHelia();
  }, []);
  return (
    <HeliaContext.Provider value={{ helia, fs, starting, error, jsonHelia }}>
      {children}
    </HeliaContext.Provider>
  );
};

export default HeliaProvider;
export const useHelia = () => {
  const { helia, fs, starting, error, jsonHelia } =
    React.useContext(HeliaContext);
  return { helia, fs, starting, error, jsonHelia };
};

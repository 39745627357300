import HomeComp from "conponents/home";
import { memo } from "react";
import styled from "styled-components";

const Home = () => {
  return (
    <HomeStyle>
      <HomeComp />
    </HomeStyle>
  );
};
export default Home;
const HomeStyle = memo(styled.div`
  height: 100%;
`);

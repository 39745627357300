import JSZip from "jszip";
import React from "react";
import { saveAs } from "file-saver";
interface ExportFileZip {
  zipData: any;
  metadata: any;
  packNumber: number;
  name: string;
}
const COUNTER_DOWNLOADS = 100;
const exportFileZip = async (
  zipData: any,
  metadata: any,
  packNumber: number,
  name: string = "",
  startIndex: number = 0
) => {
  const zip = new JSZip();

  for (let i = 0; i < zipData.length; i++) {
    zip
      .folder(`${name}/images`)
      ?.file(`${packNumber * COUNTER_DOWNLOADS + i + 1 + startIndex}.png`, zipData[i]);
    zip
      .folder(`${name}/metadata`)
      ?.file(`${packNumber * COUNTER_DOWNLOADS + i + 1 + startIndex}.json`, metadata[i]);
  }
  zip
    .generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 9,
      },
    })
    .then(
      function (content) {
        saveAs(content, `${name}.zip`);
      },
      (err) => {
        console.log("Failed to generate zip", err);
      }
    );
};

export default exportFileZip;

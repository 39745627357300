import {
  ILayer,
  IPrototypeItem,
  ITraitImage,
} from "contexts/NFTGeneratorContext";
// SelectedTraits {
//     layer_1664248999713-trait_1664249011554-1: "blob:http://localhost:3000/67e1a6f7-3503-4833-bde9-257dd1860ffe"
//     layer_1664249012268-trait_1664249021797-1: "blob:http://localhost:3000/9d253e84-3587-4c94-8c15-098adb38b758"
//     layer_1664249022307-trait_1664249031985-1: "blob:http://localhost:3000/4026e630-ade7-4706-9885-36624ec2e794"
// }

// Collection {
//     0:ISelectedTraits,
//     12: ISelectedTraits,
//     40: ISelectedTraits
// }

// MetatData {
//     0: {
//         0: { face: 'round_blue' }
//         1: { mouth: 'grin_yellow' }
//         2: {left eye: 'x_purple' }
//     },
//     1: {
//         0: { face: 'round_blue' }
//         1: { mouth: 'grin_yellow' }
//         2: {left eye: 'x_purple' }
//     }
// }
const outcomeCalc = (layers: any) => {
  return [].concat(
    ...layers.map((obj) => Array(Math.ceil(obj.weight * 100)).fill(obj.id))
  );
};
const generateCombination = (
  layers: ILayer[],
  tokenId: number,
  weights: string[]
) => {
  let selectedTraits = {},
    meta = {};
  for (let i = 0; i < layers.length; i++) {
    const randomPick = weights[Math.floor(Math.random() * weights.length)];
    if (
      layers[i].weight === 1 ||
      (layers[i].weight > 0 &&
        layers[i].weight < 1 &&
        randomPick === layers[i].id)
    ) {
      const imageList = layers[i].images;
      const generateTraits = outcomeCalc(imageList);
      const picking = generateTraits[
        Math.floor(Math.random() * generateTraits.length)
      ] as any;
      const pickedItem =
        imageList[
          imageList.findIndex((item: ITraitImage) => item.id === picking)
        ];
      meta[i] = { [layers[i].name]: pickedItem.name };
      selectedTraits[pickedItem.id] = pickedItem.image;
    }
  }
  return { selectedTraits, meta };
};

export const generateNFTs = (
  num: number,
  layers: ILayer[],
  prototypes?: IPrototypeItem[]
) => {
  // console.log('AAA', layers)
  // console.log('BBBB', prototypes)
  let generated = new Set(),
    collection = {} as any,
    startpoint = 0,
    metadata = {} as any;
  const weights = outcomeCalc(layers);
  if (prototypes) {
    // let tempCollection = {} as any,
    //     tempMetadata = {} as any;
    const includedProtArr = [...prototypes].filter(
      (item: IPrototypeItem) => !!item.inclusive
    );
    startpoint += includedProtArr.length;
    // console.log('here', includedProtArr)
    for (let i = 0; i < includedProtArr.length; i++) {
      let newTraits = {} as any,
        newMeta = {} as any,
        traitImg = includedProtArr[i].traits;
      for (let j = 0; j < traitImg.length; j++) {
        let idxLayer = layers.findIndex(
          (item: ILayer) => item.id === traitImg[j].layer
        );
        let idxImg = layers[idxLayer].images.findIndex(
          (item: ITraitImage) => item.id === traitImg[j].id
        );
        newTraits[traitImg[j].id] = layers[idxLayer].images[idxImg].image;
        newMeta[j] = {
          [layers[idxLayer].name]: layers[idxLayer].images[idxImg].name,
        };
      }
      const includedPrototype = JSON.stringify(newTraits);
      // tempCollection[i] = newTraits;
      // tempMetadata[i] = newMeta;
      // console.log('tempCollection', tempCollection)
      // console.log('tempMetadata', tempMetadata)
      generated.add(includedPrototype);
      collection[i] = newTraits;
      metadata[i] = newMeta;
    }
  }
  for (let tokenId = startpoint; tokenId < num; tokenId++) {
    // console.log(`Generating NFT #${tokenId} …`)
    let { selectedTraits, meta } = generateCombination(
      layers,
      tokenId,
      weights
    );
    // console.log('selection', selectedTraits)
    // console.log('meta', meta)
    const traitsStr = JSON.stringify(selectedTraits);
    if (traitsStr !== "{}") {
      if (generated.has(traitsStr)) {
        console.log("DUPLICATE detected. Retry …");
        tokenId--;
        continue;
      } else {
        generated.add(traitsStr);
        collection[tokenId] = selectedTraits;
        metadata[tokenId] = meta;
      }
    }
  }
  // console.log('collection', collection)
  // console.log('metadata', metadata)
  return { imageSet: collection, metadata };
};

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppV2 } from "./Appv2";
import AppContextWrap from "./contexts/AppContext";
import CollectionContextWrap from "./contexts/CollectionContext";
import NFTGeneratorContextWrap from "contexts/NFTGeneratorContext";
import { Config, DAppProvider } from "@usedapp/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// const config: Config = {
//   readOnlyChainId: Number(configGlobal.REACT_APP_CHAIN_ID),
//   readOnlyUrls: {
//       [configGlobal.REACT_APP_CHAIN_ID]: configGlobal.REACT_APP_NODE_NETWORK,
//   },
//   pollingInterval: 2000,
//   autoConnect: true,
// };
root.render(
  // <DAppProvider config={config}>
  <CollectionContextWrap>
    {/* <AppContextWrap> */}
    <NFTGeneratorContextWrap>
      <App />
    </NFTGeneratorContextWrap>
    <ToastContainer />
    {/* </AppContextWrap> */}
  </CollectionContextWrap>
  // </DAppProvider>
  // <AppV2 />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

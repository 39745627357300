import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import abiMintFactory from "constants/abiMintFactory.json";
import { useMintContext } from "contexts/MintContext";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useTronContext } from "../../../contexts/TronContext";
import { CiEdit } from "react-icons/ci";
import InputItem from "../../../conponents/InputItem";
import { useDebounce } from "../../../helpers/hooks/useDebounce";
import { TRON_EXPLORER_URL } from "../../../constants";
const SERVE_PINATA_URL = process.env.REACT_APP_CLOUD_PINATA!;
const MintNFT = () => {
  const { metadatas, contract, onChangeStep, onAddContract } = useMintContext();
  const { address } = useWallet();
  const [loading, setLoading] = useState(false);
  const { tronWeb } = useTronContext();
  const [isEditContract, setIsEditContract] = useState(false);
  const onChangeEdit = (e: boolean) => {
    setIsEditContract(e);
    if (e) {
      setNewContract(contract || "");
    } else {
      setNewContract("");
    }
  };
  const [newContract, setNewContract] = useState("");
  // const isError = useMemo(() => {
  //   if (tronWeb.isAddress(newContract)) {
  //     // onAddContract(newContract);
  //     return false;
  //   }
  //   return true;
  // }, [newContract]);
  const onChangeContract = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNewContract(value);
  };
  const onMint = async (contractAddress: string) => {
    if (!address) {
      toast.error("Please connect your wallet");
      return;
    }
    if (tronWeb.isAddress(contractAddress) === false) {
      toast.error("Address is not valid");
      return;
    }
    try {
      setLoading(true);
      const instanceMint = await (window as any).tronWeb.contract(
        abiMintFactory,
        contractAddress
      );
      const metaDatasBatch = metadatas.map((item, index) => {
        return `${SERVE_PINATA_URL}/ipfs/${item}`;
      });
      const txid = await instanceMint
        .safeMintBatch(address, metaDatasBatch)
        .send({ feeLimit: 2e9 });
      if (txid) {
        const dataOut = (await new Promise((resolve) => {
          setTimeout(() => {
            tronWeb.trx.getTransaction(txid, (err, res) => {
              resolve(res);
            });
          }, 3000);
        })) as any;
        // console.log({ dataOut });
        // const dataOut = await tronWeb.trx.getConfirmedTransaction(txid);
        // console.log({ dataOut });
        if (dataOut?.ret?.[0]?.contractRet === "SUCCESS") {
          setLoading(false);
          onChangeStep(4);
        } else {
          setLoading(false);
          toast.error("error mint nft");
        }
      }
    } catch (error) {
      console.log("error mint nft", error);
    } finally {
      setLoading(false);
    }
  };
  // const newValueContract = useDebounce(newContract, 1000);
  // useEffect(() => {
  //   if (tronWeb.isAddress(newValueContract)) {
  //     console.log({ newValueContract });
  //     onAddContract(newValueContract);
  //   }
  // }, [newValueContract]);
  return (
    <Wrap>
      <MintItem>
        <p>Total items: {metadatas.length}</p>
      </MintItem>
      <MintItem>
        <p>
          Contract Address:{" "}
          <a
            href={`${TRON_EXPLORER_URL}#/address/${contract}`}
            target="_blank"
            rel="noreferrer"
          >
            {contract}
          </a>{" "}
          {/* <span onClick={() => onChangeEdit(!isEditContract)}>
            <CiEdit />
          </span> */}
        </p>
        {/* {isEditContract && (
          <WrapInput>
            <input value={newContract} onChange={onChangeContract} />
            {isError && <p className="error">Address is not valid</p>}
          </WrapInput>
        )} */}
      </MintItem>
      <MintItem>
        <p>Method: safeMintBatch</p>
      </MintItem>

      <ButtonMint>
        <button
          onClick={() => onMint(contract || "")}
          disabled={loading || !contract}
        >
          {loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            "Mint"
          )}
        </button>
      </ButtonMint>
    </Wrap>
  );
};

export default MintNFT;
const Wrap = styled.div`
  border: 1px solid #2f9c95;
  padding: 30px;
  border-radius: 5px;
  width: min(600px, 100%);
  margin: 0 auto;
`;
const MintItem = styled.div`
  margin-bottom: 20px;
`;
const ButtonMint = styled.div`
  margin-top: 20px;
  button {
    padding: 15px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #2f9c95;
    width: 100%;
  }
`;
const WrapInput = styled.div`
  margin-top: 10px;
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  .error {
    color: red;
    font-size: 0.75rem;
    margin-top: 4px;
  }
`;

import Generating from "conponents/Generating";
import { memo, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { NFTGeneratorContext } from "contexts/NFTGeneratorContext";
import { renderCanvasImageWithBlob } from "helpers/renderCanvasImage";
import { writeFileSync } from "fs";
import { Buffer } from "buffer";
import { createMetadata } from "helpers/generateMetadata";
import exportFileZip from "./exportFileZip";
import useUploadIpfs from "../../../helpers/hooks/useUploadIpfs";
import { useLocation, useNavigate } from "react-router-dom";
interface IExportingCollection {
  onClose: () => void;
}
const COUNTER_DOWNLOADS = 100;
const ExportingCollection2 = ({ onClose }: IExportingCollection) => {
  const location = window.location;
  const [step, setStep] = useState(0);
  const {
    state: { collection, generated },
  } = useContext(NFTGeneratorContext);
  // console.log("export 2", generated);
  // const { onUploadFile } = useUploadIpfs();
  async function* powers(counter) {
    //endless loop to generate

    // console.log({ counter });
    for (let i = 0; i < counter; i++) {
      let newArrSlice = Object.keys(generated.imageSet).slice(
        i * COUNTER_DOWNLOADS,
        i * COUNTER_DOWNLOADS + COUNTER_DOWNLOADS
      );
      // console.log(
      //   { i, counter, COUNTER_DOWNLOADS, newArrSlice },
      //   i * COUNTER_DOWNLOADS,
      //   i * COUNTER_DOWNLOADS + COUNTER_DOWNLOADS
      // );
      let { imgList, metaList } = [...newArrSlice].reduce(
        (acc: any, item: any, index: any) => {
          const temp = generated.imageSet[item];
          let layerList = [...Object.values(temp ?? "")] as string[];
          const generatedImg = renderCanvasImageWithBlob({
            nftImages: layerList,
            id: "render_export",
            size: collection.size,
            imageType: "image/png",
          });
          const metaData = createMetadata({
            type: "erc721",
            dataTrait: generated.metadata[index],
            name: collection.name,
            description: collection.description,
            compiler: "ez-nft.com",
            external_url: collection.extenalLink
              ? `${collection.extenalLink}/${collection.name}/images/${
                  +item + 1
                }.png`
              : "",
            image: `${collection.name}_${+item + collection.startIndex + 1}_${
              collection?.id.split("_")?.[1] ?? ""
            }`,
          });
          // console.log({ generatedImg });
          const check = urltoFile(generatedImg);

          return {
            ...acc,
            imgList: [...acc.imgList, check],
            metaList: [...acc.metaList, JSON.stringify(metaData)],
          };
        },
        { imgList: [], metaList: [] }
      ) as any;
      const newImgList = imgList.map((item: any, i) => {
        return { path: `images/${i + 1}.png`, content: item };
      });
      // onUploadFile(newImgList, metaList);
      exportFileZip(
        imgList,
        metaList,
        i,
        collection.name,
        collection.startIndex
      );
      // console.log("before", i, counter);
      if (i + 1 === counter) {
        onClose();
        window.open(`${location.origin}/upload`, "_blank");
      }

      yield i;
      console.log("after", i);
    }
  }
  // console.log({ generated });
  const urltoFile = (img: string) => {
    const data = img.replace(/^data:image\/\w+;base64,/, "");
    const buf = Buffer.from(data, "base64");
    return buf;
  };
  useEffect(() => {
    if (generated) {
      let counter = Math.ceil(
        Object.keys(generated.imageSet).length / COUNTER_DOWNLOADS
      );
      const nextPrint = powers(counter);
      const t1 = performance.now();
      for (let i = 1; i <= counter; i++) {
        // console.log({ i, step, nextPrint });
        nextPrint.next();
      }
      // console.log("conter", counter, nextPrint);
      const t0 = performance.now();
      console.log(`Call to doSomething took ${t1 - t0} milliseconds.`, t1, t0);
    }
  }, [generated]);
  useEffect(() => {
    if (step) {
    }
  }, [step]);
  return (
    <ExportingCollectionStyle>
      <canvas id="render_export" />
      <Generating />
    </ExportingCollectionStyle>
  );
};
export default ExportingCollection2;
const ExportingCollectionStyle = memo(styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background: #fff;
`);

import { ILayer, NFTGeneratorContext } from "contexts/NFTGeneratorContext";
import { memo, useContext, useState } from "react";
import styled from "styled-components";
import { MdArrowBack } from "react-icons/md";
import { Link } from "react-router-dom";
import ExportingCollection from "./exportingCollection";
import { generateNFTs } from "conponents/generate/GenerateNFTs";
import ExportingCollection2 from "./exportingCollecttion2";

const HeaderGenerate = () => {
  const {
    state: { layer, collection, generated },
    regenerateCollection,
  } = useContext(NFTGeneratorContext);
  // console.log("generated", generated);
  const [exporting, setExporting] = useState(false);
  return (
    <HeaderGenerateStyle>
      <div className="hgs_acc">
        <div className="hgsa_download" data-tip="Back">
          <Link to="/" className="hgsad">
            <MdArrowBack size={40} />
          </Link>
        </div>
        <div className="hgsa_info">
          <div className="hgsai_wallet">{/* <p>0x946...Cc9E</p> */}</div>
          <div className="hhsai_collection_name">
            {/* <input value={colName} placeholder="Collection name" onChange={(e: any) => { setColName(e.target.value) }} /> */}
            {/* <Input value={colName} changeValue={setColName} /> */}
          </div>
        </div>
      </div>
      <div className="hgs_functional">
        <div className="hgsf_left"></div>
        <div className="hgsf_right">
          <button onClick={() => regenerateCollection()}>Regenerate</button>
          <button
            onClick={() => {
              setExporting(true);
            }}
          >
            Export collection
          </button>
        </div>
      </div>
      {exporting && (
        <ExportingCollection2 onClose={() => setExporting(false)} />
      )}
    </HeaderGenerateStyle>
  );
};

export default HeaderGenerate;
const HeaderGenerateStyle = memo(styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
  .hgs_acc {
    width: 20vw;
    padding: 10px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    .hgsa_download {
      width: 60px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #309c95;
      border-radius: 5px;
      cursor: pointer;
      .hgsad {
        color: #fff;
      }
    }
    .hgsa_info {
      margin-left: 15px;
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .hgsai_wallet {
        p {
          font-size: 12px;
        }
      }
      .hhsai_collection_name {
        /* input{
                    width:100%;
                    height:30px;
                    background:#f1f1f1;
                    border-radius:5px;
                    padding:3px 5px;
                    border:solid 1px #f1f1f1;
                    &.error{
                        border-color:#de0b00;
                    }
                } */
      }
    }
  }
  .hgs_functional {
    width: 69.3vw;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    .hgsf_left {
      width: 48.7vw;
      display: flex;
    }
    .hgsf_right {
      width: 20.6vw;
      align-self: center;
      justify-content: flex-end;
      display: flex;
      button {
        padding: 10px 15px;
        min-width: 180px;
        max-width: 100%;
        background: #2f9c95;
        border-radius: 5px;
        color: #fff;
        &:last-child {
          margin-left: 20px;
        }
        &:hover {
          background: #40c9a2;
        }
      }
    }
  }
`);

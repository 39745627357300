import React, { createContext, useReducer } from "react";
type TMint = {
  step: number;
  progress: number;
  isMinting: boolean;
  contract: string | null;
  symbol: string;
  images: string[];
  metadatas: string[];
};
export const MintContext = createContext({
  step: 1,
  contract: null as TMint["contract"],
  images: [] as TMint["images"],
  metadatas: [] as TMint["metadatas"],
  symbol: "",
  onChangeStep: (step: number) => {},
  onAddContract: (contract: string, symbol: string) => {},
  onAddImages: (images: string[]) => {},
  onAddMetadatas: (metadatas: string[]) => {},
});

type Taction =
  | {
      type: "setStep";
      step: number;
    }
  | {
      type: "setProgress";
      progress: number;
    }
  | {
      type: "setImages";
      images: string[];
    }
  | {
      type: "setMetadatas";
      metadatas: string[];
    }
  | {
      type: "setContract";
      contract: string;
      symbol: string;
    };
const MintContextWrap = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = React.useState(false);
  const initData: TMint = {
    step: 1,
    progress: 0,
    isMinting: false,
    contract: "TFME5xZB7WPfzmzKkv1KzHjbZgQ5XHBApv",
    symbol: "",
    // contract: "TXNm2JRLVFRXQV9Pz1zJUTd1nfJEyCzt95",
    // contract: "TK9Dy9G6hioq1snkzMmFgvk9s36Mzoox5i",
    // contract: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
    images: [],
    metadatas: [],
  };

  const reducer = (state: TMint, action: Taction) => {
    switch (action.type) {
      case "setStep":
        return { ...state, step: action.step };
      case "setProgress":
        return { ...state, progress: action.progress };
      case "setContract":
        return { ...state, contract: action.contract, symbol: action.symbol };
      case "setImages":
        return { ...state, images: action.images };
      case "setMetadatas":
        return { ...state, metadatas: action.metadatas };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initData);
  const onChangeStep = (step: number) => {
    dispatch({ type: "setStep", step });
  };

  const onAddContract = (contract: string, symbol: string) => {
    dispatch({ type: "setContract", contract, symbol });
  };
  const onAddImages = (images: string[]) => {
    dispatch({ type: "setImages", images });
  };
  const onAddMetadatas = (metadata: string[]) => {
    dispatch({ type: "setMetadatas", metadatas: metadata });
  };

  const { step, contract, images, metadatas, symbol } = state;
  return (
    <MintContext.Provider
      value={{
        step,
        contract,
        images,
        metadatas,
        onChangeStep,
        onAddContract,
        onAddImages,
        onAddMetadatas,
        symbol,
      }}
    >
      {children}
    </MintContext.Provider>
  );
};

export default MintContextWrap;
export const useMintContext = () => React.useContext(MintContext);

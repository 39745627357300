import React from "react";
import styled from "styled-components";
const titleStep = {
  1: "Create Contract",
  2: "Upload Cloud",
  3: "Mint NFT",
  4: "Market Ape",
};
const subTitleStep = {
  1: "To create a smart contract for your NFT collection, fill in the full name and symbol of the collection. Then, click `Create Contract` and sign the transaction to confirm the creation of the contract.",
  2: "To ensure your NFT collection is securely stored, upload the data, including images and metadata you’ve previously exported, to the cloud storage system.",
  3: "After the contract is created and the data is uploaded to the cloud, you will proceed to mint the NFTs based on the uploaded list.",
  4: "Once all settings are complete, start selling your NFTs by clicking the `Sell NFT` button.",
};
type Props = {
  step: number;
};
const Heading = ({ step }: Props) => {
  return (
    <Wrap>
      <Title>{titleStep[step]}</Title>
      <SubTitle>{subTitleStep[step]}</SubTitle>
    </Wrap>
  );
};

export default Heading;
const Wrap = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;
const Title = styled.p`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
`;
const SubTitle = styled.p`
  font-size: 16px;
  color: #0a0a0a;
  max-width: min(700px, 100%);
  margin: 0 auto;
`;

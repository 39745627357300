import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import abiFactory from "constants/abiFactory.json";
import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { CONTRACT_FACTORY, TRON_EXPLORER_URL } from "../../../constants";
import { useMintContext } from "../../../contexts/MintContext";
import { useTronContext } from "../../../contexts/TronContext";
const TronTxDecoder = require("tron-tx-decoder");

type TContractInfo = {
  name: string;
  symbol: string;
};
const CreateContract = () => {
  const decoder = new TronTxDecoder({ mainnet: false });
  async function decodeTxInput(txId) {
    const decodedInput = await decoder.decodeResultById(txId);
    return decodedInput;
  }
  const { onAddContract, contract, onChangeStep } = useMintContext();
  const { address } = useWallet();
  const { tronWeb } = useTronContext();
  const [loading, setLoading] = useState(false);
  const [contractInfo, setContractInfo] = useState<TContractInfo>({
    name: "tronsave",
    symbol: "tsl",
  });
  const onChangeInfo = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setContractInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const deployContract = async ({ name, symbol }: TContractInfo) => {
    if (!address) {
      console.log("youmustlogin");
      return;
    }
    if (name.length === 0) {
      alert("Contract name is required");
      return;
    }
    try {
      setLoading(true);
      const instanceCreateContract = await (window as any).tronWeb.contract(
        abiFactory,
        CONTRACT_FACTORY
      );
      const txidCreate = await instanceCreateContract
        .createERC721(name, symbol)
        .send({ feeLimit: 2e9 });
      if (txidCreate) {
        const dataOut = (await new Promise((resolve) => {
          setTimeout(() => {
            resolve(decodeTxInput(txidCreate));
          }, 3000);
        })) as any;
        if (dataOut) {
          onAddContract(
            tronWeb.address.fromHex(dataOut?.decodedOutput?.[0]),
            symbol
          );
          onChangeStep(2);
        }
      }
    } catch (error) {
      console.log("error deploy contract", error);
    } finally {
      setLoading(false);
    }
  };
  // console.log({ contract });
  return (
    <Wrap>
      <div className="form">
        <InputItem>
          <WrapLabel>
            <label htmlFor="name">Contract name</label>
          </WrapLabel>
          <input
            type="text"
            placeholder="Contract name"
            value={contractInfo.name}
            name="name"
            id="name"
            onChange={onChangeInfo}
          />
        </InputItem>
        <InputItem>
          <WrapLabel>
            <label htmlFor="name">Symbol</label>
          </WrapLabel>
          <input
            type="text"
            placeholder="Contract name"
            value={contractInfo.symbol}
            name="symbol"
            id="symbol"
            onChange={onChangeInfo}
          />
        </InputItem>
      </div>
      <ButtonItem>
        <button
          onClick={() => deployContract({ ...contractInfo })}
          disabled={
            !contractInfo.name || !address || !contractInfo.symbol || loading
          }
        >
          {loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            "Create contract"
          )}
        </button>
      </ButtonItem>
    </Wrap>
  );
};

export default CreateContract;
const Wrap = styled.div`
  margin-top: 40px;
  padding: 20px 40px;
  border: 1px solid #2f9c95;
  border-radius: 5px;
  width: min(600px, 100%);
  margin: 0 auto;
  /* .form {
    width: 400px;
  } */
`;
const InputItem = styled.div`
  margin-bottom: 25px;
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
`;
const WrapLabel = styled.div`
  margin-bottom: 7px;
`;
const ButtonItem = styled.div`
  margin-top: 20px;
  button {
    padding: 15px 20px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    background: #2f9c95;
    width: 100%;
    cursor: pointer;
    :disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
`;

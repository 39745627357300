import { WalletProvider } from "@tronweb3/tronwallet-adapter-react-hooks";
import { WalletModalProvider } from "@tronweb3/tronwallet-adapter-react-ui";
import {
  OkxWalletAdapter,
  TronLinkAdapter,
  WalletConnectAdapter,
} from "@tronweb3/tronwallet-adapters";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import TronWeb from "tronweb";
import "@tronweb3/tronwallet-adapter-react-ui/style.css";
const DEFAULT_ADDRESS = "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb";
const TRON_GRID_API_KEY = "4178ce83-bd13-4c14-8d74-713384f720bb" ?? "";
const TRON_GRID_API_KEY2 = "12b00ac3-df96-44e8-8cd8-9b00d0651d2a" ?? "";
// const NODE_QUICKNODE = process.env.REACT_APP_NODE_TRON_2 ?? "";
const fullNode = "https://api.shasta.trongrid.io";
// const fullNode = "https://nile.trongrid.io";

if (!fullNode) {
  throw new Error("Please config node network");
}
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID || "";
// eslint-disable-next-line global-require
window.Buffer = window.Buffer || require("buffer").Buffer;
export const TronContexts = createContext({
  isCorrectNode: true,
  tronWeb: undefined as any,
  changeCorrectNode: (e: boolean) => {},
  wsReconnected: false as null | boolean,
  changeWsReconnected: (e: boolean) => {},
});

export enum WalletReadyState {
  /**
   * Adapter will start to check if wallet exists after adapter instance is created.
   */
  Loading = "Loading",
  /**
   * When checking ends and wallet is not found, readyState will be NotFound.
   */
  NotFound = "NotFound",
  /**
   * When checking ends and wallet is found, readyState will be Found.
   */
  Found = "Found",
}

const TronContextsWrap = ({ children }: { children: React.ReactNode }) => {
  //check node
  const [tronWeb, setTronWeb] = useState<any>(
    () =>
      new TronWeb({
        fullHost: fullNode,
        // headers: { "TRON-PRO-API-KEY": "4178ce83-bd13-4c14-8d74-713384f720bb" },
        privateKey:
          "882efc4feb24ead6da9167c6740e27a1fc6c0b95074e6b6e47e8b1e831043ac6",
      })
  );
  useEffect(() => {
    (async () => {
      try {
        if (!tronWeb) return;
        const infoAccount = await tronWeb.trx.getAccount(DEFAULT_ADDRESS);
        if (!infoAccount?.address) {
          setTronWeb(
            new TronWeb({
              fullHost: fullNode,
              headers: { "TRON-PRO-API-KEY": TRON_GRID_API_KEY2 },
              privateKey:
                "882efc4feb24ead6da9167c6740e27a1fc6c0b95074e6b6e47e8b1e831043ac6",
            })
          );
        }
      } catch (error) {
        console.log("error node", error);
      }
    })();
  }, []);
  const [isCorrectNode, setIsCorrectNode] = useState(true);
  const [wsReconnected, setWsReconnected] = useState<null | boolean>(null);
  const adapters = useMemo(function () {
    // const tronLinkAdapter = new TronLinkAdapter();
    // const walletConnectAdapter = new WalletConnectAdapter({
    //   network: "Nile",
    //   options: {
    //     relayUrl: "wss://relay.walletconnect.com",
    //     // example WC app project ID
    //     // projectId: '5fc507d8fc7ae913fff0b8071c7df231',
    //     projectId: PROJECT_ID,
    //     metadata: {
    //       name: "TronSave",
    //       description: "TronSave WalletConnect",
    //       url: "https://tronsave.io/",
    //       icons: [logo],
    //     },
    //   },
    //   web3ModalConfig: {
    //     themeMode: "dark",
    //     themeVariables: {
    //       "--w3m-z-index": "1000",
    //     },
    //     explorerRecommendedWalletIds: [
    //       "20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66",
    //       "19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927",
    //     ],
    //   },
    // });
    const tronLinkAdapter = new TronLinkAdapter();
    const okxwalletAdapter = new OkxWalletAdapter();
    return [tronLinkAdapter, okxwalletAdapter];
  }, []);

  const changeCorrectNode = (e: boolean) => {
    setIsCorrectNode(e);
  };
  const changeWsReconnected = (e: boolean) => {
    setWsReconnected((prevState: null | boolean) => {
      if (e === false) {
        return false;
      }
      if (prevState === false) {
        return e;
      }
      return null;
    });
  };
  return (
    <TronContexts.Provider
      value={{
        isCorrectNode,
        tronWeb,
        changeCorrectNode,
        wsReconnected,
        changeWsReconnected,
      }}
    >
      <WalletProvider
        adapters={adapters}
        autoConnect={true}
        disableAutoConnectOnLoad={true}
      >
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </TronContexts.Provider>
  );
};

export default TronContextsWrap;
export const useTronContext = () => useContext(TronContexts);

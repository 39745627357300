import styled from "styled-components";
import { useMintContext } from "../../contexts/MintContext";
import Header from "./components/Header";
import CreateContract from "./contract/CreateContract";
import MarketApe from "./market/MarketApe";
import MintNFT from "./mint/MintNFT";
import UploadPinata from "./upload/UploadPinata";
import StepContainer from "./components/step/StepContainer";
import Heading from "./components/heading/Heading";
const titleStep = [
  "Create Contract",
  "Upload Cloud",
  "Upload Cloud",
  "Upload Cloud",
];
const UploadPage = () => {
  const { step, onChangeStep } = useMintContext();
  const ELStep = ({ step }: { step: number }) => {
    switch (step) {
      case 1:
        return (
          <>
            <CreateContract />
          </>
        );
      case 2:
        return (
          <>
            <UploadPinata />
            {/* <UploadCloud /> */}
          </>
        );
      case 3:
        return (
          <>
            <MintNFT />
          </>
        );
      case 4:
        return (
          <>
            <MarketApe />
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <WrapContain>
      <div className="" style={{ marginBottom: 40 }}>
        <Header />
      </div>
      <StepContainer />
      <Wrap>
        <div>
          <Heading step={step} />
        </div>
        <div>
          <ELStep step={step} />
        </div>
      </Wrap>
    </WrapContain>
  );
};

export default UploadPage;
const WrapContain = styled.div``;
const Wrap = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

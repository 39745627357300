import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useMintContext } from "../../../contexts/MintContext";
import { pinata } from "../../../lib/pinata";
const SERVE_PINATA_URL = process.env.REACT_APP_CLOUD_PINATA!;
async function parseJsonFile(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event: any) =>
      resolve(JSON.parse(event.target.result));
    fileReader.onerror = (error) => reject(error);
    fileReader.readAsText(file);
  });
}

const UploadPinata = () => {
  const { onAddMetadatas, onChangeStep } = useMintContext();

  const [loading, setLoading] = useState(false);
  const [folder, setFolder] = useState("tronsave");
  const [files, setFiles] = useState<any>([]);
  const [arrMetadata, setArrMetadata] = useState<any>([]);
  const handleFolderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.length;
    if (!file) return;
    const selectedFiles = Array.from(event.target.files as FileList);
    setFiles(selectedFiles);
  };
  const handleMetadataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.length;
    if (!file) return;
    const selectedFiles = Array.from(event.target.files as FileList);
    setArrMetadata(selectedFiles);
  };
  const onUploadFiles = async (
    folder: string,
    images: any[],
    arrData: any[]
  ) => {
    if (!folder) {
      toast.error("Please enter folder name");
      return;
    }
    // if (datas.length > 0) {
    //   onAddMetadatas(datas);
    //   onChangeStep(3);
    //   return;
    // }
    try {
      setLoading(true);
      // console.log({ folder, images, arrData });
      const group = await pinata.groups.create({ name: folder });
      if (group?.id) {
        const cidImage = await pinata.upload.fileArray(files, {
          metadata: { name: folder },
          groupId: group.id,
        });
        // console.log({ cidImage });
        if (cidImage.IpfsHash && arrData.length > 0) {
          //TODO convert metadata with image upload
          const arrMetadataWithImage = await Promise.all(
            arrData.map(async (item, index) => {
              const fileJson = (await parseJsonFile(arrMetadata[0])) as any;
              return {
                ...fileJson,
                image: `${SERVE_PINATA_URL}/ipfs/${cidImage.IpfsHash}/${images[index].name}`,
              };
            })
          );
          // console.log("arrMetadataWithImage", arrMetadataWithImage);
          if (arrMetadataWithImage.length === images.length) {
            const uploadWithDelay = async (item, delay) => {
              return new Promise((resolve) => {
                setTimeout(async () => {
                  const data = await pinata.upload.json(item);
                  resolve(data.IpfsHash);
                }, delay);
              });
            };

            const arrCIDs = (await new Promise(async (resolve) => {
              const cids = [] as any;
              for (let i = 0; i < arrMetadataWithImage.length; i++) {
                const cid = await uploadWithDelay(arrMetadataWithImage[i], 300);
                cids.push(cid);
              }
              resolve(cids);
            })) as string[];
            // console.log({ arrCIDs });

            if (arrCIDs.length === images.length) {
              // const arrCIDs = await Promise.all(uploadPromises);
              // console.log({ arrCIDs });
              onAddMetadatas(arrCIDs);
              setLoading(false);
              onChangeStep(3);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Wrap>
      <div>
        <InputItem>
          <label htmlFor="folder">Folder name</label>
          <input
            type="text"
            onChange={(e) => setFolder(e.target.value)}
            value={folder}
          />
        </InputItem>
        <InputItem>
          <div style={{ marginBottom: 20 }}>
            <label htmlFor="folder">Image</label>
            <input
              type="file"
              multiple
              onChange={handleFolderChange}
              accept="image/png"
            />
          </div>
        </InputItem>
        <InputItem>
          <label htmlFor="folder">Metadata</label>
          <input
            type="file"
            multiple
            onChange={handleMetadataChange}
            accept="application/json"
          />
        </InputItem>
        <ButtonSubmit>
          <button onClick={() => onUploadFiles(folder, files, arrMetadata)}>
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Upload"
            )}
          </button>
        </ButtonSubmit>
      </div>
    </Wrap>
  );
};

export default UploadPinata;
const Wrap = styled.div`
  border: 1px solid #2f9c95;
  padding: 30px;
  border-radius: 5px;
  width: min(600px, 100%);
  margin: 0 auto;
`;
const ButtonSubmit = styled.div`
  margin-top: 20px;
  button {
    padding: 15px 20px;
    border-radius: 5px;
    background: #2f9c95;
    width: 100%;
  }
`;
const InputItem = styled.div`
  margin-bottom: 20px;
  label {
    display: block;
    margin-bottom: 7px;
  }
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
`;

import React, { createContext, useEffect } from "react";
import { PinataSDK } from "pinata";
const PinataContext = createContext({
  pinata: undefined as any,
});
const PinataContextWrap = ({ children }: { children: React.ReactNode }) => {
  const pinata = new PinataSDK({
    pinataJwt: process.env.REACT_APP_PINATA_JWT!,
    pinataGateway: "scarlet-holy-bee-599.mypinata.cloud",
  });
  // const testAuth = async () => {
  //   try {
  //     const files = await pinata
  //       .listFiles()
  //       .cid("bafybeidig2msrwbqdbkaw2rbh4wtxfuwvk27poon4slet6k63gf2cayzhu")
  //       .pageLimit(10);
  //     console.log({ files });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   testAuth();
  // }, []);
  return (
    <PinataContext.Provider value={{ pinata }}>
      {children}
    </PinataContext.Provider>
  );
};

export default PinataContextWrap;
export const usePinataContext = () => React.useContext(PinataContext);

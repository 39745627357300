import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTronContext } from "../../../contexts/TronContext";
import { ethers } from "ethers";
function generateSalt() {
  const firstDigit = Math.floor(Math.random() * 9) + 1; // First digit cannot be 0
  const remainingDigits = Array.from({ length: 76 }, () =>
    Math.floor(Math.random() * 10)
  ).join(""); // Remaining 76 digits
  return firstDigit + remainingDigits;
}
function orderSign(signature) {
  const result = signature.substring(2);
  const r = "0x" + result.substring(0, 64);
  const s = "0x" + result.substring(64, 128);
  const v = parseInt(result.substring(128, 130), 16); // The signature is now comprised of r, s, and v.
  return {
    r,
    s,
    v,
    // signature,
  };
}
function createERC721DataToCall(methodId, to, tokenId) {
  const abi = new ethers.utils.AbiCoder();

  // Encode the methodId, zeroed `from` address, `to` address, and `tokenId`
  const dataToCall =
    methodId +
    abi
      .encode(
        ["address", "address", "uint256"],
        ["0x0000000000000000000000000000000000000000", to, tokenId]
      )
      .slice(2); // Slice to remove '0x' from the encoded values

  return dataToCall;
}
type Props = {
  tokenUri: any;
  tokenId: string;
  contract: string;
  symbol: string;
};
const NftItem = ({ tokenUri, tokenId, contract, symbol }: Props) => {
  const { tronWeb } = useTronContext();
  const { signMessage, address } = useWallet();
  const [info, setInfo] = useState<{
    name: string;
    description: string;
    image: string;
    attributes: any[];
  }>({
    name: "",
    description: "",
    image: "",
    attributes: [],
  });
  const getImage = async () => {
    const data = await fetch(tokenUri).then((res) => res.json());
    const { image, name, description, attributes } = data;
    setInfo({
      name,
      description,
      image,
      attributes,
    });
  };
  useEffect(() => {
    if (tokenUri) getImage();
  }, [tokenUri]);
  const { name, image, description, attributes } = info;
  const onCreateParam = async () => {
    try {
      const res = await fetch(
        "https://api-gateway-staging.apenft.io/api/v3/order/build_create_param",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            asset: {
              asset_type: 2,
              contract: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
              quantity: "1",
              token_id: "3",
            },
            chain: "tron",
            end_time: 1726116569,
            english_auction_reserve_price: "0",
            maker: "TKVSaJQDWeKFSEXmA44pjxduGTxyXa6B52",
            order_kind: 0,
            order_side: 0,
            payment: {
              price: "1000000",
              token: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb", //trx
              amount: "1000000",
            },
            recipient: "",
            signature:
              "0xe358e291c82fa349521695af31751ad853e430dbae7b2fbe1031e22f4b61a1855c6f97c1397cc0cbc0c5a7fc8bb6cc14cc1d685e4ae77214f1c7a183473ef0931c",
            start_time: 1723524606,
          }),
        }
      );
    } catch (error) {
      console.log("error create param", error);
    }
  };
  const onCreateOrder = async (params: any) => {
    const dataSign = {};
    console.log("info on create order", params, dataSign);
    try {
      const newParams = {
        ...params,
        ...dataSign,
        howToCall: 0,
      };
      const new1 = {
        basePrice: "1000000",
        dataToCall:
          "0x23b872dd000000000000000000000000a104d1b682f1068cc9e13e943c09b2439a1a23a6000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001c6",
        englishAuctionReservePrice: "0",
        exchange: "TYnuR3ripQNZUY3vjk7zbwQ48zHKdAtzis",
        expirationTime: "1650459847",
        extra: "0",
        feeRecipient: "TWhpdixJek7oTWiSgiw1vB9d6wakv5Ntac",
        hash: "0x01c3c19d75be520569b81c50c7315e0b62b05297afdd31f5fe364370c3ee5696",
        listingTime: "1650459847",
        maker: "TQebZnbcfpwwPaEmyQt0X9EpV5k2Mq88ui",
        makerRelayerFee: "0",
        metadata: {
          asset: {
            address: "TQ9Rxiv2H3JeDC4zW6GQ6ZAHC5xCmacBsV",
            id: "2",
          },
          schema: "ERC721",
        },
        paymentToken: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        quantity: "1",
        r: "0xdad85ebf01d57f0414ed68b674f24a7b06407bf68d311acdf6daf50ec9c9864d",
        replacementPattern:
          "0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000",
        s: "0x59ac49422662d102719823735fe9f1e3f793298689e60beca34980d431f66d49",
        saleKind: 0,
        salt: "23568344558784782936679170265198715627992689485756827974971615835940603257706",
        side: 1,
        staticExtraData: "0x",
        staticTarget: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        taker: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        takerRelayerFee: "0",
        target: "TQ9Rxiv2HzJeDC4zWacG8ZAHC5xCmacBsV",
        v: 0,
      };
      const dataTrue = {
        exchange: "TYnuR3ripQNZUYUvjkjzbwQ48zHKdAtzis",
        maker: "TKVSaJQDWeKFSEXmA44pjxduGTxyXa6B52",
        taker: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        makerRelayerFee: "0",
        takerRelayerFee: "0",
        makerProtocolFee: "200",
        takerProtocolFee: "0",
        makerReferrerFee: "0",
        feeMethod: 1,
        feeRecipient: "TLRt2GZrfxBsYWjsh7wjmZbXT3WKMoEws9",
        side: 1,
        saleKind: 0,
        target: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
        howToCall: 0,
        dataToCall:
          "0x23b872dd0000000000000000000000006870f0f1d517cde13587914d959d949fef46fb7a00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000003",
        replacementPattern:
          "0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000",
        staticTarget: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        staticExtradata: "0x",
        paymentToken: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        quantity: "1",
        basePrice: "1000000",
        extra: "0",
        listingTime: (new Date().getTime() / 1000).toFixed(0),
        expirationTime: (new Date().getTime() / 1000 + 7200).toFixed(0),
        salt: "77098031326398746019539670907343584385095255826636752546726528602735443564097",
        metadata: {
          asset: {
            id: "2",
            address: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
          },
          schema: "ERC721",
          version: 2,
        },
        v: 27,
        r: "0x1c4cc5bdeaf04f5c26b8c4074b4877d5ed742185838229552189aec02d1d3e79",
        s: "0x0789d7e9964c020b207c08571f9326a17c87dc21978602359d5ce9d8b757afc9",
        hash: "0xe2eba3e3c8669da64eb12f92326444f6c3d288c3b2ed9011c288974be9baf802",
        version: 0,
        chain: "tron",
        chainId: "0x94a9059e",
      };
      const dataNFT1 = {
        exchange: "TYnuR3ripQNZUYUvjkjzbwQ48zHKdAtzis",
        maker: "TKVSaJQDWeKFSEXmA44pjxduGTxyXa6B52",
        taker: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        makerRelayerFee: "0",
        takerRelayerFee: "0",
        makerProtocolFee: "200",
        takerProtocolFee: "0",
        makerReferrerFee: "0",
        feeMethod: 1,
        feeRecipient: "TLRt2GZrfxBsYWjsh7wjmZbXT3WKMoEws9",
        side: 1,
        saleKind: 0,
        target: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
        howToCall: 0,
        dataToCall:
          "0x23b872dd0000000000000000000000006870f0f1d517cde13587914d959d949fef46fb7a00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001",
        replacementPattern:
          "0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000",
        staticTarget: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        staticExtradata: "0x",
        paymentToken: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        quantity: "1",
        basePrice: "12000000",
        extra: "0",
        listingTime: "1726216039",
        expirationTime: "1726220220",
        salt: "76909245830098630689069774181073906330674851926784047682277555860484224453233",
        metadata: {
          asset: {
            id: "1",
            address: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
          },
          schema: "ERC721",
          version: 2,
        },
        v: 27,
        r: "0x4e5bceb4d4bb7c35947dc02af405e9dee5c33146a47610019ba2bbedca5e3ee7",
        s: "0x7bdc2d25e444f2cb6b78ce5c1b963a84fb6e8905f532a4699c9023ed816bc448",
        hash: "0x1c768f6d577e4bd5e0d8bfc2ea5102936b3d507c2dbc085c994a7711fe6929b1",
        version: 0,
        chain: "tron",
        chainId: "0x94a9059e",
      };
      const dataTrueConvert = {
        ...dataTrue,
        ...dataSign,
        salt: params?.salt,
        dataToCall: params?.dataToCall,
        replacementPattern: params?.replacementPattern,
      };
      const newData = {
        ...dataTrue,
      };
      // console.log("params", params);
      // console.log("data true", dataTrue);
      // console.log("dataConvert", dataTrueConvert);

      const order1 = {
        exchange: "TYnuR3ripQNZUYUvjkjzbwQ48zHKdAtzis",
        maker: "TKVSaJQDWeKFSEXmA44pjxduGTxyXa6B52",
        taker: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        makerRelayerFee: "0",
        takerRelayerFee: "0",
        makerProtocolFee: "200",
        takerProtocolFee: "0",
        feeRecipient: "TLRt2GZrfxBsYWjsh7wjmZbXT3WKMoEws9",
        feeMethod: 1,
        side: 1,
        saleKind: 0,
        target: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
        howToCall: 0,
        dataToCall:
          "0x23b872dd000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000002",
        replacementPattern:
          "0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000",
        staticTarget: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        staticExtradata: "0x",
        paymentToken: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        basePrice: "1000000",
        extra: "0",
        listingTime: (new Date().getTime() / 1000).toFixed(0),
        expirationTime: (new Date().getTime() / 1000 + 7200).toFixed(0),
        salt: "61218070018688938873881537500924780693002681754850341064655765197729684511320",
      };
      const order = {
        ...order1,
        salt: params?.salt,
        dataToCall: params?.dataToCall,
        replacementPattern: params?.replacementPattern,
      };
      let functionSelector =
        "hashOrder((address,address,address,uint256,uint256,uint256,uint256,address,uint8,uint8,uint8,address,uint8,bytes,bytes,address,bytes,address,uint256,uint256,uint256,uint256,uint256))";
      let options = {
        callValue: 0,
        funcABIV2: {
          inputs: [
            { internalType: "address", name: "exchange", type: "address" },
            { internalType: "address", name: "maker", type: "address" },
            { internalType: "address", name: "taker", type: "address" },
            {
              internalType: "uint256",
              name: "makerRelayerFee",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "takerRelayerFee",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "makerProtocolFee",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "takerProtocolFee",
              type: "uint256",
            },
            {
              internalType: "address",
              name: "feeRecipient",
              type: "address",
            },
            { internalType: "uint8", name: "feeMethod", type: "uint8" },
            { internalType: "uint8", name: "side", type: "uint8" },
            { internalType: "uint8", name: "saleKind", type: "uint8" },
            { internalType: "address", name: "target", type: "address" },
            { internalType: "uint8", name: "howToCall", type: "uint8" },
            { internalType: "bytes", name: "dataToCall", type: "bytes" },
            {
              internalType: "bytes",
              name: "replacementPattern",
              type: "bytes",
            },
            {
              internalType: "address",
              name: "staticTarget",
              type: "address",
            },
            { internalType: "bytes", name: "staticExtradata", type: "bytes" },
            {
              internalType: "address",
              name: "paymentToken",
              type: "address",
            },
            { internalType: "uint256", name: "basePrice", type: "uint256" },
            { internalType: "uint256", name: "extra", type: "uint256" },
            { internalType: "uint256", name: "listingTime", type: "uint256" },
            {
              internalType: "uint256",
              name: "expirationTime",
              type: "uint256",
            },
            { internalType: "uint256", name: "salt", type: "uint256" },
          ],
          name: "hashOrder",
          outputs: [
            { internalType: "bytes32", name: "orderHash", type: "bytes32" },
          ],
          stateMutability: "view",
          type: "function",
        },
        parametersV2: [
          order.exchange, // address
          order.maker, // address
          order.taker, // address
          order.makerRelayerFee, // uint256
          order.takerRelayerFee, // uint256
          order.makerProtocolFee, // uint256
          order.takerProtocolFee, // uint256
          order.feeRecipient, // address
          order.feeMethod, // uint8
          order.side, // uint8
          order.saleKind, // uint8
          order.target, // address
          order.howToCall, // uint8
          order.dataToCall, // bytes
          order.replacementPattern, // bytes
          order.staticTarget, // address
          order.staticExtradata, // bytes
          order.paymentToken, // address
          order.basePrice, // uint256
          order.extra, // uint256
          order.listingTime, // uint256
          order.expirationTime, // uint256
          order.salt, // uint256
        ],
      } as any;

      const dataEs = {
        request_address: address,
        contract_address: "TCWuXtzPEqKJ2MLygtCr27i1ehYFJKDBdT",
        function_selector: functionSelector,
        options,
        parameters: [],
      };
      console.log({ dataEs });
      const transaction = await tronWeb.transactionBuilder.triggerSmartContract(
        "TCWuXtzPEqKJ2MLygtCr27i1ehYFJKDBdT",
        dataEs.function_selector,
        dataEs.options,
        dataEs.parameters
      );
      // const signature = await (window as any).tronWeb.trx.sign(transaction);
      console.log("trabsaction", transaction);
      if (transaction?.transaction?.txID) {
        console.log("trabsaction", transaction?.transaction?.txID);
        const signature = await (window as any).tronWeb.trx.sign(transaction);
        const requestOptions2 = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(order),
          redirect: "follow",
        } as any;
        fetch("https://api-testnet.apenft.io/v1/orders/post", requestOptions2)
          .then((response) => response.json())
          .then((result2) => {
            console.log({ result2 });
          });
      }
    } catch (error) {
      console.log("error create order", error);
    }
  };
  const onCreateOrder2 = async () => {
    const dataSign = {};
    console.log("info on create order", dataSign);
    try {
      const order1 = {
        exchange: "TYnuR3ripQNZUYUvjkjzbwQ48zHKdAtzis",
        maker: "TKVSaJQDWeKFSEXmA44pjxduGTxyXa6B52",
        taker: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        makerRelayerFee: "0",
        takerRelayerFee: "0",
        makerProtocolFee: "200",
        takerProtocolFee: "0",
        feeRecipient: "TLRt2GZrfxBsYWjsh7wjmZbXT3WKMoEws9",
        feeMethod: 1,
        side: 1,
        saleKind: 0,
        target: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
        howToCall: 0,
        dataToCall:
          "0x23b872dd000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001",
        replacementPattern:
          "0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000",
        staticTarget: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        staticExtradata: "0x",
        paymentToken: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        basePrice: "1000000",
        extra: "0",
        listingTime: (new Date().getTime() / 1000).toFixed(0),
        expirationTime: 1726570797,
        salt: "61218070018688938873881537500924780693002681754850341064655765197729684511320",
      };

      const order = {
        ...order1,
        // salt: params?.salt,
        // dataToCall: createERC721DataToCall(
        //   "0x23b872dd",
        //   "0x0000000000000000000000000000000000000000",
        //   "1"
        // ),
        // // dataToCall:
        // // "0x23b872dd000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001",
        // salt: generateSalt(),
      };

      let functionSelector =
        "hashOrder((address,address,address,uint256,uint256,uint256,uint256,address,uint8,uint8,uint8,address,uint8,bytes,bytes,address,bytes,address,uint256,uint256,uint256,uint256,uint256))";
      let options = {
        funcABIV2: {
          inputs: [
            {
              components: [
                { internalType: "address", name: "exchange", type: "address" },
                { internalType: "address", name: "maker", type: "address" },
                { internalType: "address", name: "taker", type: "address" },
                {
                  internalType: "uint256",
                  name: "makerRelayerFee",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "takerRelayerFee",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "makerProtocolFee",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "takerProtocolFee",
                  type: "uint256",
                },
                {
                  internalType: "address",
                  name: "feeRecipient",
                  type: "address",
                },
                { internalType: "uint8", name: "feeMethod", type: "uint8" },
                { internalType: "uint8", name: "side", type: "uint8" },
                { internalType: "uint8", name: "saleKind", type: "uint8" },
                { internalType: "address", name: "target", type: "address" },
                { internalType: "uint8", name: "howToCall", type: "uint8" },
                { internalType: "bytes", name: "dataToCall", type: "bytes" },
                {
                  internalType: "bytes",
                  name: "replacementPattern",
                  type: "bytes",
                },
                {
                  internalType: "address",
                  name: "staticTarget",
                  type: "address",
                },
                {
                  internalType: "bytes",
                  name: "staticExtradata",
                  type: "bytes",
                },
                {
                  internalType: "address",
                  name: "paymentToken",
                  type: "address",
                },
                { internalType: "uint256", name: "basePrice", type: "uint256" },
                { internalType: "uint256", name: "extra", type: "uint256" },
                {
                  internalType: "uint256",
                  name: "listingTime",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "expirationTime",
                  type: "uint256",
                },
                { internalType: "uint256", name: "salt", type: "uint256" },
              ],
            },
          ],
          name: "hashOrder",
          outputs: [
            { internalType: "bytes32", name: "orderHash", type: "bytes32" },
          ],
          stateMutability: "view",
          type: "function",
        },
        parametersV2: [
          [
            order.exchange, // address
            order.maker, // address
            order.taker, // address
            order.makerRelayerFee, // uint256
            order.takerRelayerFee, // uint256
            order.makerProtocolFee, // uint256
            order.takerProtocolFee, // uint256
            order.feeRecipient, // address
            order.feeMethod, // uint8
            order.side, // uint8
            order.saleKind, // uint8
            order.target, // address
            order.howToCall, // uint8
            order.dataToCall, // bytes
            order.replacementPattern, // bytes
            order.staticTarget, // address
            order.staticExtradata, // bytes
            order.paymentToken, // address
            order.basePrice, // uint256
            order.extra, // uint256
            order.listingTime, // uint256
            order.expirationTime, // uint256
            order.salt, // uint256
          ],
        ],
      } as any;

      const dataEs = {
        request_address: address,
        contract_address: "TCWuXtzPEqKJ2MLygtCr27i1ehYFJKDBdT",
        function_selector: functionSelector,
        options,
        // parameters: [],
        parameters: [
          {
            type: "address",
            value: order.exchange,
          },
          {
            type: "address",
            value: order.maker,
          },
          {
            type: "address",
            value: order.taker,
          },
          {
            type: "uint256",
            value: order.makerRelayerFee,
          },
          {
            type: "uint256",
            value: order.takerRelayerFee,
          },
          {
            type: "uint256",
            value: order.makerProtocolFee,
          },
          {
            type: "uint256",
            value: order.takerProtocolFee,
          },
          {
            type: "address",
            value: order.feeRecipient,
          },
          {
            type: "uint8",
            value: order.feeMethod,
          },
          {
            type: "uint8",
            value: order.side,
          },
          {
            type: "uint8",
            value: order.saleKind,
          },
          {
            type: "address",
            value: order.target,
          },
          {
            type: "uint8",
            value: order.howToCall,
          },
          {
            type: "bytes",
            value: order.dataToCall,
          },
          {
            type: "bytes",
            value: order.replacementPattern,
          },
          {
            type: "address",
            value: order.staticTarget,
          },
          {
            type: "bytes",
            value: order.staticExtradata,
          },
          {
            type: "address",
            value: order.paymentToken,
          },
          {
            type: "uint256",
            value: order.basePrice,
          },
          {
            type: "uint256",
            value: order.extra,
          },
          {
            type: "uint256",
            value: order.listingTime,
          },
          {
            type: "uint256",
            value: order.expirationTime,
          },
          {
            type: "uint256",
            value: order.salt,
          },
        ],
      };
      // console.log({ dataEs });
      const transaction =
        await tronWeb.transactionBuilder.triggerConstantContract(
          tronWeb.address.toHex("TCWuXtzPEqKJ2MLygtCr27i1ehYFJKDBdT"),
          dataEs.function_selector,
          // dataEs.options,
          {},
          dataEs.parameters,
          tronWeb.address.toHex("TP3DLC7qyVzSvF6xvTUaHdBDPcyy2Zy4Qx")
        );
      // const signature = await (window as any).tronWeb.trx.sign(transaction);
      console.log("trabsactionsss", transaction);
      // if (transaction?.constant_result?.[0]) {
      if (transaction?.transaction?.txID) {
        console.log("transaction data");
        const signature = await (window as any).tronWeb.trx.sign(
          transaction?.transaction?.txID
        );
        console.log("signature", signature);
        const a2 = {
          exchange: "TYnuR3ripQNZUYUvjkjzbwQ48zHKdAtzis",
          maker: "TKVSaJQDWeKFSEXmA44pjxduGTxyXa6B52",
          taker: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
          makerRelayerFee: "0",
          takerRelayerFee: "0",
          makerProtocolFee: "200",
          takerProtocolFee: "0",
          makerReferrerFee: "0",
          feeMethod: 1,
          feeRecipient: "TLRt2GZrfxBsYWjsh7wjmZbXT3WKMoEws9",
          side: 1,
          saleKind: 0,
          target: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
          howToCall: 0,
          dataToCall:
            "0x23b872dd0000000000000000000000006870f0f1d517cde13587914d959d949fef46fb7a00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000002",
          replacementPattern:
            "0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000",
          staticTarget: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
          staticExtradata: "0x",
          paymentToken: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
          quantity: "1",
          basePrice: "1000000",
          extra: "0",
          listingTime: (new Date().getTime() / 1000).toFixed(0),
          expirationTime: 1726570797,
          salt: "92892671233654252661526594706009235545995280544780414724789138783703085998699",
          metadata: {
            asset: {
              id: "2",
              address: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
            },
            schema: "ERC721",
            version: 2,
          },
          v: 27,
          r: "0x4b875d35ef38a2f4fda16688e3dce39fe646f5aba354e5de665d27151da32ef3",
          s: "0x3bbae71947be54dba1a1856d6e50e45de52d1b2c06af68912e026b375e511888",
          hash: "0xd71697e8a05a6ecdf8ae00681d0d19559775a242a38130b870bb9f611071590d",
          version: 0,
          chain: "tron",
          chainId: "0x94a9059e",
        };
        const { s, r, v } = orderSign(signature);
        const order2 = {
          ...order1,
          ...a2,
          // salt: params?.salt,
          // dataToCall: createERC721DataToCall(
          //   "0x23b872dd",
          //   tronWeb.address.toHex("TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w"),
          //   "1"
          // ),
          // dataToCall:
          // "0x23b872dd000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001",
          salt: generateSalt(),
          hash: transaction?.transaction?.txID,
          v,
          r,
          s,
          signature: signature,
        };

        const requestOptions2 = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(order2),
          redirect: "follow",
        } as any;
        fetch("https://api-testnet.apenft.io/v1/orders/post", requestOptions2)
          .then((response) => response.json())
          .then((result2) => {
            console.log({ result2 });
          });
      }
      // fetch("https://api-testnet.apenft.io/v1/orders/post", requestOptions2)
      //   .then((response) => response.json())
      //   .then((result2) => {
      //     console.log({ result2 });
      //   });
      // const signTxid = await signMessage()

      // const signTxid = await signMessage()
      // const res = await fetch(
      //   "https://api-gateway-staging.apenft.io/api/v3/order",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       maker: "TKVSaJQDWeKFSEXmA44pjxduGTxyXa6B52",
      //       chain: "tron",
      //       build_param_req: {
      //         asset: {
      //           asset_type: 2,
      //           contract: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
      //           quantity: "1",
      //           token_id: "3",
      //         },
      //         chain: "tron",
      //         end_time: 1726116569,
      //         english_auction_reserve_price: "0",
      //         maker: "TKVSaJQDWeKFSEXmA44pjxduGTxyXa6B52",
      //         order_kind: 0,
      //         order_side: 0,
      //         payment: {
      //           price: "1000000",
      //           token: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
      //           amount: "1000000",
      //         },
      //         recipient: "",
      //         signature:
      //           "0xe358e291c82fa349521695af31751ad853e430dbae7b2fbe1031e22f4b61a1855c6f97c1397cc0cbc0c5a7fc8bb6cc14cc1d685e4ae77214f1c7a183473ef0931c",
      //         start_time: 1723524606,
      //       },
      //       order_param: {
      //         offerer: "TKVSaJQDWeKFSEXmA44pjxduGTxyXa6B52",
      //         zone: "0x23cafcac35dee6f487493f7eea284d8689b8c179",
      //         offer: [
      //           {
      //             item_type: 2,
      //             token: "TBVQ9swGkFLDXPDf1ZtZZTYWYkuPmJkU3w",
      //             identifier_or_criteria: "3",
      //             start_amount: "1",
      //             end_amount: "1",
      //           },
      //         ],
      //         consideration: [
      //           {
      //             item_type: 0,
      //             token: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
      //             identifier_or_criteria: "0",
      //             start_amount: "995000",
      //             end_amount: "995000",
      //             recipient: "TKVSaJQDWeKFSEXmA44pjxduGTxyXa6B52",
      //           },
      //           {
      //             item_type: 0,
      //             token: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
      //             identifier_or_criteria: "0",
      //             start_amount: "5000",
      //             end_amount: "5000",
      //             recipient: "TRxMn4k6ZpPhHLEqVojaXRzr3PP3p3B9KE",
      //           },
      //         ],
      //         order_type: 0,
      //         start_time: "1723524606",
      //         end_time: "1726116569",
      //         zone_hash:
      //           "0x0000000000000000000000000000000000000000000000000000000000000000",
      //         salt: "111006389293093700562657077805008329468651719092311660180140654214864267098466",
      //         conduit_key:
      //           "0x0000000000000000000000000000000000000000000000000000000000000000",
      //         total_original_consideration_items: "2",
      //         counter: "0",
      //       },
      //       server_signature: "",
      //       client_signature:
      //         "0xea0e42c08001d4a90853c21345bde36fb53643c132e46f391cea651ebb66bfb530846ef20a4b1cd1c005d236f68eecc4781be62808276fec0e29f5808c52a2571c",
      //       signature:
      //         "0xe358e291c82fa349521695af31751ad853e430dbae7b2fbe1031e22f4b61a1855c6f97c1397cc0cbc0c5a7fc8bb6cc14cc1d685e4ae77214f1c7a183473ef0931c",
      //     }),
      //   }
      // );
    } catch (error) {
      console.log("error create order", error);
    }
  };
  const onCreateHashOrder = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        basePrice: "100000000",
        expirationTime: "1653124380",
        listingTime: "1650459847",
        maker: "TLmvi4E7zxSsmQ2NvzC41pS9U7j8NuKAay",
        paymentToken: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        quantity: "1",
        salt: "61218070018688938873881537500924780693002681754850341064655765197729684511320",
        schema: "ERC721",
        side: 1,
        taker: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        target: "TZC7kx5xCNZuN34ooLt13Zx4iZn3mMgkWv",
        tokenId: "2",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      } as any;
      onCreateOrder2();
      // fetch("http://34.101.87.64:5003/openapi/v1/hashOrder", requestOptions)
      //   .then((response) => response.json())
      //   .then(async (result) => {
      //     console.log({ result });
      //     // const dataOrderSign = await orderSign(result?.data?.orderHash);
      //     // console.log({ dataOrderSign });
      //     // onCreateOrder(result?.data, dataOrderSign);
      //     onCreateOrder2();
      //   })
      //   .catch((error) => console.error(error));
    } catch (error) {
      console.log("error create order", error);
    }
  };
  const linkSell = `https://testnet.apenft.io/#/account/asset/${contract}/${tokenId}`;
  return (
    <Wrap>
      <Image>
        <img src={image} alt="" style={{ width: "200px", height: "200px" }} />
      </Image>
      <WrapContent>
        <p>
          {symbol || "NFT name"} #{tokenId}
        </p>
        {/* <p>{description || "NFT description"}</p> */}
      </WrapContent>
      <WrapButton>
        <a href={linkSell} target="_blank" rel="noreferrer">
          <button>Sell NFT</button>
          {/* <button onClick={onCreateHashOrder}>Sell NFT</button> */}
        </a>
      </WrapButton>
    </Wrap>
  );
};

export default NftItem;
const Wrap = styled.div`
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.2);
`;
const Image = styled.div`
  overflow: hidden;
  :hover {
    img {
      transform: scale(1.2);
    }
  }
`;
const WrapContent = styled.div`
  padding-top: 20px;
  border-top: 1px solid #ccc;
  p {
    margin-bottom: 10px;
  }
`;
const WrapButton = styled.div`
  margin-top: 20px;
  button {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #2f9c95;
    width: 100%;
  }
`;
